<template>
  <v-row class="flex-grow-0" no-gutters>
    <v-col cols="12">
      <div v-if="Object.keys($store.state.millennium.salesMonthTotal).length === 0"  class="pa-3">
        {{Object.keys($store.state.millennium.salesMonthTotal).length === 0 ? 'carregando...' : ''}}
      </div>
    </v-col>
    <v-col cols="12" sm="12" lg="3" v-for="(item, i) in getIndicators(indicators)" :key="i" class="pr-3 pt-1 pb-2 pb-sm-0">
      <indicator
          v-if="Object.keys($store.state.millennium.salesMonthTotal).length > 0"
          :id="i"
          :title="item.title"
          :type="item.type"
          :amount="item.amount"
          :quant="item.quant"
          :sales="item.sales"
          :datetime="item.datetime"
          :border-color="item.borderColor"
      />
    </v-col>
  </v-row>
</template>

<script>
import Indicator from "@/components/widgets/indicator";
export default {
  components: {Indicator},
  data: () => ({
    indicators: [
      {type: 'amount', title: 'Vendas de Hoje', amount: 0, datetime: new Date(), borderColor: '#FFA726'},
      {type: 'quant', title: 'Quantidade (QTDE)', quant: 0, datetime: new Date(), borderColor: '#68addf'},
      {type: 'amount', title: 'Tiket Médio (TM)', amount: 0, datetime: new Date(), borderColor: '#3949ab'},
      {type: 'quant', title: 'Número de Vendas (NV)', quant: 0, datetime: new Date(), borderColor: '#43A047'}
    ],
  }),
  computed: {
    sales() {
      return this.$store.state.millennium;
    },
  },
  methods: {
    getIndicators(indicators) {
      // total de vendas Hoje
      indicators[0].amount = this.sales.salesTodayTotal.amount;
      // quantidade de produtos vendidos
      indicators[1].quant = this.sales.salesTodayTotal.quant;
      // tiket medio
      indicators[2].amount = this.sales.salesTodayTotalQuant === 0 ? 0.0 : this.sales.salesTodayTotal.amount / this.sales.salesTodayTotalQuant
      // quantidade de vendas
      indicators[3].quant = this.sales.salesTodayTotal.sales;

      return indicators;
    }
  }
}
</script>

<style scoped>

</style>
