<template>
  <div class="indicator fill-height">
    <v-card :class="`card-widget card-widget-${id}`" height="100%">
      <v-layout fill-height>
        <v-card-text class="pa-1 px-2">
          <!-- Title -->
          <v-row class="flex-grow-0" no-gutters>
            <v-col cols="8" class="title mr-2 subtitle-2">
              {{ title }}
            </v-col>
          </v-row>
          <!-- Amount -->
          <v-row v-if="type === 'amount'" class="flex-grow-0 pt-4" no-gutters>
            <v-col cols="8" class="mr-2">
              <div class="text-no-wrap">
                <span class="symbol-currency subtitle-2">R$</span>
                <span class="amount title"> {{getAmountInterger}},</span>
                <span class="amount-decimal title">{{getAmountDecimal}}</span>
              </div>
            </v-col>
          </v-row>
          <!-- Quant -->
          <v-row v-else class="flex-grow-0 pt-4" no-gutters>
            <v-col cols="8" class="mr-2">
              <div class="text-no-wrap">
                <span class="quant title">{{ number(quant) }}</span>
              </div>
            </v-col>
          </v-row>
          <!-- DateTime -->
          <v-row class="flex-grow-0" no-gutters>
            <v-col cols="12">
              <small class="grey--text text--darken-1">{{$store.state.millennium.datetimeUpdate}}</small>
            </v-col>
          </v-row>
        </v-card-text>
      </v-layout>
    </v-card>
  </div>
</template>

<script>
import mixins from '@/mixins'
export default {
  mixins: [mixins],
  props: ['id','title', 'amount', 'datetime', 'borderColor','quant','type'],
  computed: {
    getAmountInterger() {
      return this.currency(this.amount.toFixed(2)).toString().split('.')[0].replace(',','.')
    },
    getAmountDecimal() {
      return this.amount.toFixed(2).toString().split('.')[1]
    },
    getDateTime() {
      const date = new Date(this.datetime);
      return date.toLocaleDateString() + ' ' + date.toLocaleTimeString().substr(0,5)
    },   
  },
  mounted() {
    const cardWidget = document.querySelector(`.indicator .card-widget-${this.id}`)
    cardWidget.setAttribute('style', `border-left: solid 0.3rem ${this.borderColor}`)
  }
}
</script>

<style lang="scss">
.indicator {
  .card-widget {

    .symbol-currency {
      font-size: 1.2rem!important;
    }

    .amount, .quant {
      font-size: 2rem !important;

      &-decimal {
        font-size: 1.4rem!important;;
      }
    }
  }
}

@media(max-width: 758px)  {
  .indicator {
    .card-widget {

      .symbol-currency {
        font-size: 1rem!important;
      }

      .amount, .quant {
        font-size: 1.6rem !important;

        &-decimal {
          font-size: 1.2rem!important;;
        }
      }
    }
  }
}
</style>
