var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home-kardex-brief"},[_c('custom-filters',{attrs:{"show-affiliates":true,"affiliate-default":_vm.millennium.kardexAffiliateDefault,"start-date":"2019-01-01","end-date":this.getDate(new Date()),"affiliates-cols":"12","affiliate-multiples":false,"affiliate-clearable":false,"on-search":_vm.onSearch,"status-success":_vm.millennium.kardexs.length > 0,"loading":_vm.millennium.kardexsStatus === 1,"loading-text":"Buscando informações aguarde..."}}),(!_vm.millennium.kardexs || (_vm.millennium.kardexsStatus !== 1 && _vm.millennium.kardexs.length === 0))?_c('v-layout',{attrs:{"justify-start":"","justify-center":""}},[_c('i',{staticClass:"grey--text py-4 subtitle-1"},[_vm._v("Nenhuma informação foi encontrada.")])]):(_vm.millennium.kardexsStatus === 0 && _vm.millennium.kardexs.length > 0)?_c('div',{staticClass:"px-4 mt-0 mb-5"},[_c('v-data-table',{attrs:{"fixed-header":"","disable-pagination":"","hide-default-footer":"","headers":_vm.headers,"items":_vm.millennium.kardexs,"dense":"","disable-sort":""},scopedSlots:_vm._u([{key:"item.details",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"details-container"},[_c('v-layout',{staticClass:"py-2 flex-grow-1",attrs:{"align-start":""}},[_c('v-layout',{staticClass:"pl-3 pt-0",staticStyle:{"width":"100%"},attrs:{"column":""}},[_c('div',{staticClass:"pt-0 font-weight-bold",staticStyle:{"background-color":"#1a237e","color":"#fff","padding":"2px 5px"}},[_c('div',{staticStyle:{"margin-top":"5px"}},[_vm._v(" "+_vm._s(item.year)+" ")])]),_c('div',{staticClass:"field field-unidades"},[_vm._v("Unidades (Qtd Vendida)")]),_c('div',{staticClass:"field field-faturamento"},[_vm._v("Faturamento")]),(_vm.millennium.kardexs.indexOf(item) >= 1)?_c('div',{staticClass:"field field-crescimento"},[_vm._v(" "+_vm._s(("Crescimento " + (_vm.millennium.kardexs[_vm.millennium.kardexs.indexOf(item)-1].year) + " vs. " + (_vm.millennium.kardexs[_vm.millennium.kardexs.indexOf(item)].year) + " %"))+" ")]):_vm._e(),_c('div',{staticClass:"field field-margem-bruta"},[_vm._v("Margem Bruta %")]),_c('div',{staticClass:"field field-ticket"},[_vm._v("Ticket Médio")]),_c('div',{staticClass:"field field-qtd-devolvidas"},[_vm._v("Qtd. Und. Devolvidas")]),_c('div',{staticClass:"field field-val-devolvidas"},[_vm._v("Valor Und. Devolvidas")]),_c('div',{staticClass:"field field-estoque-val"},[_vm._v("Estoque (Valor)")]),_c('div',{staticClass:"field field-estoque-und"},[_vm._v("Estoque (Unidades)")])])],1)],1)]}},{key:"item.months.jan",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"details-container"},[_c('v-layout',{staticClass:"py-2",attrs:{"align-center":""}},[_c('v-layout',{staticClass:"pl-3",attrs:{"column":""}},[_c('div',{staticClass:"caption text-uppercase"},[_vm._v("janeiro")]),_c('div',{staticClass:"field field-unidades"},[_vm._v(_vm._s(_vm.getField('jan','quantidade_vendida', item)))]),_c('div',{staticClass:"field field-faturamento"},[_vm._v(_vm._s(_vm.currency(_vm.getField('jan','valor_faturamento', item))))]),(_vm.millennium.kardexs.indexOf(item) >= 1)?_c('div',{staticClass:"field field-crescimento"},[_c('div',[_vm._v(" "+_vm._s(_vm.formatPercent(_vm.getPercent(item, _vm.millennium.kardexs, 'jan', 'valor_faturamento')))+" ")])]):_vm._e(),_c('div',{staticClass:"field field-margem-bruta"},[_vm._v(_vm._s(_vm.currency(_vm.getField('jan','margem_bruta', item))))]),_c('div',{staticClass:"field field-ticket"},[_vm._v(_vm._s(_vm.getField('jan','quantidade_vendida', item) === 0 ? 'R$ 0,00' : _vm.currency(_vm.getField('jan','valor_faturamento', item) / _vm.getField('jan','quantidade_vendida', item))))]),_c('div',{staticClass:"field field-qtd-devolvidas"},[_vm._v(_vm._s(_vm.getField('jan','quantidade_devolvida', item)))]),_c('div',{staticClass:"field field-val-devolvidas"},[_vm._v(_vm._s(_vm.currency(_vm.getField('jan','valor_devolvido', item))))]),_c('div',{staticClass:"field field-estoque-val"},[_vm._v(_vm._s(_vm.currency(_vm.getField('jan','valor_estoque', item))))]),_c('div',{staticClass:"field field-estoque-und"},[_vm._v(_vm._s(_vm.currency(_vm.getField('jan','estoque', item))))])])],1)],1)]}},{key:"item.months.fev",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"details-container"},[_c('v-layout',{staticClass:"py-2",attrs:{"align-center":""}},[_c('v-layout',{staticClass:"pl-3",attrs:{"column":""}},[_c('div',{staticClass:"caption text-uppercase"},[_vm._v("fevereiro")]),_c('div',{staticClass:"field field-unidades"},[_vm._v(_vm._s(_vm.getField('fev','quantidade_vendida', item)))]),_c('div',{staticClass:"field field-faturamento"},[_vm._v(_vm._s(_vm.currency(_vm.getField('fev','valor_faturamento', item))))]),(_vm.millennium.kardexs.indexOf(item) >= 1)?_c('div',{staticClass:"field field-crescimento"},[_c('div',[_vm._v(" "+_vm._s(_vm.formatPercent(_vm.getPercent(item, _vm.millennium.kardexs, 'fev', 'valor_faturamento')))+" ")])]):_vm._e(),_c('div',{staticClass:"field field-margem-bruta"},[_vm._v(_vm._s(_vm.currency(_vm.getField('fev','margem_bruta', item))))]),_c('div',{staticClass:"field field-ticket"},[_vm._v(_vm._s(_vm.getField('fev','quantidade_vendida', item) === 0 ? 'R$ 0,00' : _vm.currency(_vm.getField('fev','valor_faturamento', item) / _vm.getField('fev','quantidade_vendida', item))))]),_c('div',{staticClass:"field field-qtd-devolvidas"},[_vm._v(_vm._s(_vm.getField('fev','quantidade_devolvida', item)))]),_c('div',{staticClass:"field field-val-devolvidas"},[_vm._v(_vm._s(_vm.currency(_vm.getField('fev','valor_devolvido', item))))]),_c('div',{staticClass:"field field-estoque-val"},[_vm._v(_vm._s(_vm.currency(_vm.getField('fev','valor_estoque', item))))]),_c('div',{staticClass:"field field-estoque-und"},[_vm._v(_vm._s(_vm.currency(_vm.getField('fev','estoque', item))))])])],1)],1)]}},{key:"item.months.mar",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"details-container"},[_c('v-layout',{staticClass:"py-2",attrs:{"align-center":""}},[_c('v-layout',{staticClass:"pl-3",attrs:{"column":""}},[_c('div',{staticClass:"caption text-uppercase"},[_vm._v("março")]),_c('div',{staticClass:"field field-unidades"},[_vm._v(_vm._s(_vm.getField('mar','quantidade_vendida', item)))]),_c('div',{staticClass:"field field-faturamento"},[_vm._v(_vm._s(_vm.currency(_vm.getField('mar','valor_faturamento', item))))]),(_vm.millennium.kardexs.indexOf(item) >= 1)?_c('div',{staticClass:"field field-crescimento"},[_c('div',[_vm._v(" "+_vm._s(_vm.formatPercent(_vm.getPercent(item, _vm.millennium.kardexs, 'mar', 'valor_faturamento')))+" ")])]):_vm._e(),_c('div',{staticClass:"field field-margem-bruta"},[_vm._v(_vm._s(_vm.currency(_vm.getField('mar','margem_bruta', item))))]),_c('div',{staticClass:"field field-ticket"},[_vm._v(_vm._s(_vm.getField('mar','quantidade_vendida', item) === 0 ? 'R$ 0,00' : _vm.currency(_vm.getField('mar','valor_faturamento', item) / _vm.getField('mar','quantidade_vendida', item))))]),_c('div',{staticClass:"field field-qtd-devolvidas"},[_vm._v(_vm._s(_vm.getField('mar','quantidade_devolvida', item)))]),_c('div',{staticClass:"field field-val-devolvidas"},[_vm._v(_vm._s(_vm.currency(_vm.getField('mar','valor_devolvido', item))))]),_c('div',{staticClass:"field field-estoque-val"},[_vm._v(_vm._s(_vm.currency(_vm.getField('mar','valor_estoque', item))))]),_c('div',{staticClass:"field field-estoque-und"},[_vm._v(_vm._s(_vm.currency(_vm.getField('mar','estoque', item))))])])],1)],1)]}},{key:"item.months.abr",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"details-container"},[_c('v-layout',{staticClass:"py-2",attrs:{"align-center":""}},[_c('v-layout',{staticClass:"pl-3",attrs:{"column":""}},[_c('div',{staticClass:"caption text-uppercase"},[_vm._v("abril")]),_c('div',{staticClass:"field field-unidades"},[_vm._v(_vm._s(_vm.getField('abr','quantidade_vendida', item)))]),_c('div',{staticClass:"field field-faturamento"},[_vm._v(_vm._s(_vm.currency(_vm.getField('abr','valor_faturamento', item))))]),(_vm.millennium.kardexs.indexOf(item) >= 1)?_c('div',{staticClass:"field field-crescimento"},[_c('div',[_vm._v(" "+_vm._s(_vm.formatPercent(_vm.getPercent(item, _vm.millennium.kardexs, 'abr', 'valor_faturamento')))+" ")])]):_vm._e(),_c('div',{staticClass:"field field-margem-bruta"},[_vm._v(_vm._s(_vm.currency(_vm.getField('abr','margem_bruta', item))))]),_c('div',{staticClass:"field field-ticket"},[_vm._v(_vm._s(_vm.getField('abr','quantidade_vendida', item) === 0 ? 'R$ 0,00' : _vm.currency(_vm.getField('abr','valor_faturamento', item) / _vm.getField('abr','quantidade_vendida', item))))]),_c('div',{staticClass:"field field-qtd-devolvidas"},[_vm._v(_vm._s(_vm.getField('abr','quantidade_devolvida', item)))]),_c('div',{staticClass:"field field-val-devolvidas"},[_vm._v(_vm._s(_vm.currency(_vm.getField('abr','valor_devolvido', item))))]),_c('div',{staticClass:"field field-estoque-val"},[_vm._v(_vm._s(_vm.currency(_vm.getField('abr','valor_estoque', item))))]),_c('div',{staticClass:"field field-estoque-und"},[_vm._v(_vm._s(_vm.currency(_vm.getField('abr','estoque', item))))])])],1)],1)]}},{key:"item.months.mai",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"details-container"},[_c('v-layout',{staticClass:"py-2",attrs:{"align-center":""}},[_c('v-layout',{staticClass:"pl-3",attrs:{"column":""}},[_c('div',{staticClass:"caption text-uppercase"},[_vm._v("maio")]),_c('div',{staticClass:"field field-unidades"},[_vm._v(_vm._s(_vm.getField('mai','quantidade_vendida', item)))]),_c('div',{staticClass:"field field-faturamento"},[_vm._v(_vm._s(_vm.currency(_vm.getField('mai','valor_faturamento', item))))]),(_vm.millennium.kardexs.indexOf(item) >= 1)?_c('div',{staticClass:"field field-crescimento"},[_c('div',[_vm._v(" "+_vm._s(_vm.formatPercent(_vm.getPercent(item, _vm.millennium.kardexs, 'mai', 'valor_faturamento')))+" ")])]):_vm._e(),_c('div',{staticClass:"field field-margem-bruta"},[_vm._v(_vm._s(_vm.currency(_vm.getField('mai','margem_bruta', item))))]),_c('div',{staticClass:"field field-ticket"},[_vm._v(_vm._s(_vm.getField('mai','quantidade_vendida', item) === 0 ? 'R$ 0,00' : _vm.currency(_vm.getField('mai','valor_faturamento', item) / _vm.getField('mai','quantidade_vendida', item))))]),_c('div',{staticClass:"field field-qtd-devolvidas"},[_vm._v(_vm._s(_vm.getField('mai','quantidade_devolvida', item)))]),_c('div',{staticClass:"field field-val-devolvidas"},[_vm._v(_vm._s(_vm.currency(_vm.getField('mai','valor_devolvido', item))))]),_c('div',{staticClass:"field field-estoque-val"},[_vm._v(_vm._s(_vm.currency(_vm.getField('mai','valor_estoque', item))))]),_c('div',{staticClass:"field field-estoque-und"},[_vm._v(_vm._s(_vm.currency(_vm.getField('mai','estoque', item))))])])],1)],1)]}},{key:"item.months.jun",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"details-container"},[_c('v-layout',{staticClass:"py-2",attrs:{"align-center":""}},[_c('v-layout',{staticClass:"pl-3",attrs:{"column":""}},[_c('div',{staticClass:"caption text-uppercase"},[_vm._v("junho")]),_c('div',{staticClass:"field field-unidades"},[_vm._v(_vm._s(_vm.getField('jun','quantidade_vendida', item)))]),_c('div',{staticClass:"field field-faturamento"},[_vm._v(_vm._s(_vm.currency(_vm.getField('jun','valor_faturamento', item))))]),(_vm.millennium.kardexs.indexOf(item) >= 1)?_c('div',{staticClass:"field field-crescimento"},[_c('div',[_vm._v(" "+_vm._s(_vm.formatPercent(_vm.getPercent(item, _vm.millennium.kardexs, 'jun', 'valor_faturamento')))+" ")])]):_vm._e(),_c('div',{staticClass:"field field-margem-bruta"},[_vm._v(_vm._s(_vm.currency(_vm.getField('jun','margem_bruta', item))))]),_c('div',{staticClass:"field field-ticket"},[_vm._v(_vm._s(_vm.getField('jun','quantidade_vendida', item) === 0 ? 'R$ 0,00' : _vm.currency(_vm.getField('jun','valor_faturamento', item) / _vm.getField('jun','quantidade_vendida', item))))]),_c('div',{staticClass:"field field-qtd-devolvidas"},[_vm._v(_vm._s(_vm.getField('jun','quantidade_devolvida', item)))]),_c('div',{staticClass:"field field-val-devolvidas"},[_vm._v(_vm._s(_vm.currency(_vm.getField('jun','valor_devolvido', item))))]),_c('div',{staticClass:"field field-estoque-val"},[_vm._v(_vm._s(_vm.currency(_vm.getField('jun','valor_estoque', item))))]),_c('div',{staticClass:"field field-estoque-und"},[_vm._v(_vm._s(_vm.currency(_vm.getField('jun','estoque', item))))])])],1)],1)]}},{key:"item.months.jul",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"details-container"},[_c('v-layout',{staticClass:"py-2",attrs:{"align-center":""}},[_c('v-layout',{staticClass:"pl-3",attrs:{"column":""}},[_c('div',{staticClass:"caption text-uppercase"},[_vm._v("julho")]),_c('div',{staticClass:"field field-unidades"},[_vm._v(_vm._s(_vm.getField('jul','quantidade_vendida', item)))]),_c('div',{staticClass:"field field-faturamento"},[_vm._v(_vm._s(_vm.currency(_vm.getField('jul','valor_faturamento', item))))]),(_vm.millennium.kardexs.indexOf(item) >= 1)?_c('div',{staticClass:"field field-crescimento"},[_c('div',[_vm._v(" "+_vm._s(_vm.formatPercent(_vm.getPercent(item, _vm.millennium.kardexs, 'jul', 'valor_faturamento')))+" ")])]):_vm._e(),_c('div',{staticClass:"field field-margem-bruta"},[_vm._v(_vm._s(_vm.currency(_vm.getField('jul','margem_bruta', item))))]),_c('div',{staticClass:"field field-ticket"},[_vm._v(_vm._s(_vm.getField('jul','quantidade_vendida', item) === 0 ? 'R$ 0,00' : _vm.currency(_vm.getField('jul','valor_faturamento', item) / _vm.getField('jul','quantidade_vendida', item))))]),_c('div',{staticClass:"field field-qtd-devolvidas"},[_vm._v(_vm._s(_vm.getField('jul','quantidade_devolvida', item)))]),_c('div',{staticClass:"field field-val-devolvidas"},[_vm._v(_vm._s(_vm.currency(_vm.getField('jul','valor_devolvido', item))))]),_c('div',{staticClass:"field field-estoque-val"},[_vm._v(_vm._s(_vm.currency(_vm.getField('jul','valor_estoque', item))))]),_c('div',{staticClass:"field field-estoque-und"},[_vm._v(_vm._s(_vm.currency(_vm.getField('jul','estoque', item))))])])],1)],1)]}},{key:"item.months.ago",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"details-container"},[_c('v-layout',{staticClass:"py-2",attrs:{"align-center":""}},[_c('v-layout',{staticClass:"pl-3",attrs:{"column":""}},[_c('div',{staticClass:"caption text-uppercase"},[_vm._v("agosto")]),_c('div',{staticClass:"field field-unidades"},[_vm._v(_vm._s(_vm.getField('ago','quantidade_vendida', item)))]),_c('div',{staticClass:"field field-faturamento"},[_vm._v(_vm._s(_vm.currency(_vm.getField('ago','valor_faturamento', item))))]),(_vm.millennium.kardexs.indexOf(item) >= 1)?_c('div',{staticClass:"field field-crescimento"},[_c('div',[_vm._v(" "+_vm._s(_vm.formatPercent(_vm.getPercent(item, _vm.millennium.kardexs, 'ago', 'valor_faturamento')))+" ")])]):_vm._e(),_c('div',{staticClass:"field field-margem-bruta"},[_vm._v(_vm._s(_vm.currency(_vm.getField('ago','margem_bruta', item))))]),_c('div',{staticClass:"field field-ticket"},[_vm._v(_vm._s(_vm.getField('ago','quantidade_vendida', item) === 0 ? 'R$ 0,00' : _vm.currency(_vm.getField('ago','valor_faturamento', item) / _vm.getField('ago','quantidade_vendida', item))))]),_c('div',{staticClass:"field field-qtd-devolvidas"},[_vm._v(_vm._s(_vm.getField('ago','quantidade_devolvida', item)))]),_c('div',{staticClass:"field field-val-devolvidas"},[_vm._v(_vm._s(_vm.currency(_vm.getField('ago','valor_devolvido', item))))]),_c('div',{staticClass:"field field-estoque-val"},[_vm._v(_vm._s(_vm.currency(_vm.getField('ago','valor_estoque', item))))]),_c('div',{staticClass:"field field-estoque-und"},[_vm._v(_vm._s(_vm.currency(_vm.getField('ago','estoque', item))))])])],1)],1)]}},{key:"item.months.set",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"details-container"},[_c('v-layout',{staticClass:"py-2",attrs:{"align-center":""}},[_c('v-layout',{staticClass:"pl-3",attrs:{"column":""}},[_c('div',{staticClass:"caption text-uppercase"},[_vm._v("setembro")]),_c('div',{staticClass:"field field-unidades"},[_vm._v(_vm._s(_vm.getField('set','quantidade_vendida', item)))]),_c('div',{staticClass:"field field-faturamento"},[_vm._v(_vm._s(_vm.currency(_vm.getField('set','valor_faturamento', item))))]),(_vm.millennium.kardexs.indexOf(item) >= 1)?_c('div',{staticClass:"field field-crescimento"},[_c('div',[_vm._v(" "+_vm._s(_vm.formatPercent(_vm.getPercent(item, _vm.millennium.kardexs, 'set', 'valor_faturamento')))+" ")])]):_vm._e(),_c('div',{staticClass:"field field-margem-bruta"},[_vm._v(_vm._s(_vm.currency(_vm.getField('set','margem_bruta', item))))]),_c('div',{staticClass:"field field-ticket"},[_vm._v(_vm._s(_vm.getField('set','quantidade_vendida', item) === 0 ? 'R$ 0,00' : _vm.currency(_vm.getField('set','valor_faturamento', item) / _vm.getField('set','quantidade_vendida', item))))]),_c('div',{staticClass:"field field-qtd-devolvidas"},[_vm._v(_vm._s(_vm.getField('set','quantidade_devolvida', item)))]),_c('div',{staticClass:"field field-val-devolvidas"},[_vm._v(_vm._s(_vm.currency(_vm.getField('set','valor_devolvido', item))))]),_c('div',{staticClass:"field field-estoque-val"},[_vm._v(_vm._s(_vm.currency(_vm.getField('set','valor_estoque', item))))]),_c('div',{staticClass:"field field-estoque-und"},[_vm._v(_vm._s(_vm.currency(_vm.getField('set','estoque', item))))])])],1)],1)]}},{key:"item.months.out",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"details-container"},[_c('v-layout',{staticClass:"py-2",attrs:{"align-center":""}},[_c('v-layout',{staticClass:"pl-3",attrs:{"column":""}},[_c('div',{staticClass:"caption text-uppercase"},[_vm._v("outubro")]),_c('div',{staticClass:"field field-unidades"},[_vm._v(_vm._s(_vm.getField('out','quantidade_vendida', item)))]),_c('div',{staticClass:"field field-faturamento"},[_vm._v(_vm._s(_vm.currency(_vm.getField('out','valor_faturamento', item))))]),(_vm.millennium.kardexs.indexOf(item) >= 1)?_c('div',{staticClass:"field field-crescimento"},[_c('div',[_vm._v(" "+_vm._s(_vm.formatPercent(_vm.getPercent(item, _vm.millennium.kardexs, 'out', 'valor_faturamento')))+" ")])]):_vm._e(),_c('div',{staticClass:"field field-margem-bruta"},[_vm._v(_vm._s(_vm.currency(_vm.getField('out','margem_bruta', item))))]),_c('div',{staticClass:"field field-ticket"},[_vm._v(_vm._s(_vm.getField('out','quantidade_vendida', item) === 0 ? 'R$ 0,00' : _vm.currency(_vm.getField('out','valor_faturamento', item) / _vm.getField('out','quantidade_vendida', item))))]),_c('div',{staticClass:"field field-qtd-devolvidas"},[_vm._v(_vm._s(_vm.getField('out','quantidade_devolvida', item)))]),_c('div',{staticClass:"field field-val-devolvidas"},[_vm._v(_vm._s(_vm.currency(_vm.getField('out','valor_devolvido', item))))]),_c('div',{staticClass:"field field-estoque-val"},[_vm._v(_vm._s(_vm.currency(_vm.getField('out','valor_estoque', item))))]),_c('div',{staticClass:"field field-estoque-und"},[_vm._v(_vm._s(_vm.currency(_vm.getField('out','estoque', item))))])])],1)],1)]}},{key:"item.months.nov",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"details-container"},[_c('v-layout',{staticClass:"py-2",attrs:{"align-center":""}},[_c('v-layout',{staticClass:"pl-3",attrs:{"column":""}},[_c('div',{staticClass:"caption text-uppercase"},[_vm._v("novembro")]),_c('div',{staticClass:"field field-unidades"},[_vm._v(_vm._s(_vm.getField('nov','quantidade_vendida', item)))]),_c('div',{staticClass:"field field-faturamento"},[_vm._v(_vm._s(_vm.currency(_vm.getField('nov','valor_faturamento', item))))]),(_vm.millennium.kardexs.indexOf(item) >= 1)?_c('div',{staticClass:"field field-crescimento"},[_c('div',[_vm._v(" "+_vm._s(_vm.formatPercent(_vm.getPercent(item, _vm.millennium.kardexs, 'nov', 'valor_faturamento')))+" ")])]):_vm._e(),_c('div',{staticClass:"field field-margem-bruta"},[_vm._v(_vm._s(_vm.currency(_vm.getField('nov','margem_bruta', item))))]),_c('div',{staticClass:"field field-ticket"},[_vm._v(_vm._s(_vm.getField('nov','quantidade_vendida', item) === 0 ? 'R$ 0,00' : _vm.currency(_vm.getField('nov','valor_faturamento', item) / _vm.getField('nov','quantidade_vendida', item))))]),_c('div',{staticClass:"field field-qtd-devolvidas"},[_vm._v(_vm._s(_vm.getField('nov','quantidade_devolvida', item)))]),_c('div',{staticClass:"field field-val-devolvidas"},[_vm._v(_vm._s(_vm.currency(_vm.getField('nov','valor_devolvido', item))))]),_c('div',{staticClass:"field field-estoque-val"},[_vm._v(_vm._s(_vm.currency(_vm.getField('nov','valor_estoque', item))))]),_c('div',{staticClass:"field field-estoque-und"},[_vm._v(_vm._s(_vm.currency(_vm.getField('nov','estoque', item))))])])],1)],1)]}},{key:"item.months.dez",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"details-container"},[_c('v-layout',{staticClass:"py-2",attrs:{"align-center":""}},[_c('v-layout',{staticClass:"pl-3",attrs:{"column":""}},[_c('div',{staticClass:"caption text-uppercase"},[_vm._v("dezembro")]),_c('div',{staticClass:"field field-unidades"},[_vm._v(_vm._s(_vm.getField('dez','quantidade_vendida', item)))]),_c('div',{staticClass:"field field-faturamento"},[_vm._v(_vm._s(_vm.currency(_vm.getField('dez','valor_faturamento', item))))]),(_vm.millennium.kardexs.indexOf(item) >= 1)?_c('div',{staticClass:"field field-crescimento"},[_c('div',[_vm._v(" "+_vm._s(_vm.formatPercent(_vm.getPercent(item, _vm.millennium.kardexs, 'dez', 'valor_faturamento')))+" ")])]):_vm._e(),_c('div',{staticClass:"field field-margem-bruta"},[_vm._v(_vm._s(_vm.currency(_vm.getField('dez','margem_bruta', item))))]),_c('div',{staticClass:"field field-ticket"},[_vm._v(_vm._s(_vm.getField('dez','quantidade_vendida', item) === 0 ? 'R$ 0,00' : _vm.currency(_vm.getField('dez','valor_faturamento', item) / _vm.getField('dez','quantidade_vendida', item))))]),_c('div',{staticClass:"field field-qtd-devolvidas"},[_vm._v(_vm._s(_vm.getField('dez','quantidade_devolvida', item)))]),_c('div',{staticClass:"field field-val-devolvidas"},[_vm._v(_vm._s(_vm.currency(_vm.getField('dez','valor_devolvido', item))))]),_c('div',{staticClass:"field field-estoque-val"},[_vm._v(_vm._s(_vm.currency(_vm.getField('dez','valor_estoque', item))))]),_c('div',{staticClass:"field field-estoque-und"},[_vm._v(_vm._s(_vm.currency(_vm.getField('dez','estoque', item))))])])],1)],1)]}}])})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }