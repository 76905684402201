<template>
  <v-card class="home-chart-table-panel chart-card">
    <v-card-title>
      <v-layout justify-space-between align-center>
        <div class="body-2">
          <!--          {{ titles[chartBtnToggle] }}-->
        </div>
        <div title="expandir">
          <v-btn icon @click="$store.commit('setShowFullscreenChart', true)">
            <v-icon>mdi-open-in-new</v-icon>
          </v-btn>
        </div>
      </v-layout>
    </v-card-title>
    <v-card-text class="pa-2">
      <div v-if="Object.keys($store.state.millennium.salesMonthTotal).length === 0" class="pa-3">
        {{Object.keys($store.state.millennium.salesMonthTotal).length === 0 ? 'carregando...' : ''}}
      </div>
      <v-row v-else class="flex-grow-0" no-gutters>
        <!-- Chart -->
        <v-col cols="12">
          <chart v-model="chartBtnToggle">
            <template #table>
              <data-table class="mt-4" :indicator="chartBtnToggle" />
            </template>
          </chart>
        </v-col>
        <!-- Table -->
        <v-col v-if="Object.keys($store.state.millennium.salesMonthTotal).length > 0">
          <data-table :indicator="chartBtnToggle" />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import Chart from "@/components/pages/home/chart";
import DataTable from "@/components/pages/home/data-table";
export default {
  components: {DataTable, Chart},
  data: () => ({
    titles: ['VENDAS','NÚMERO DE VENDAS', 'TIKET MÉDIO', 'QUANTIDADE'],
    chartBtnToggle: undefined,
  })
}
</script>

<style scoped>

</style>
