<template>
  <v-layout class="percent-indicator-comparative">
    <v-icon v-if="valueA === undefined" color="orange">{{iconMiddle}}</v-icon>
    <v-icon v-else :color="isZero ? 'orange darken-1' : isGTZero ? 'green' : 'red'">
      {{isZero ? iconMiddle : isGTZero ? iconUp : iconDown}}
    </v-icon>
    <small v-if="valueB === undefined" class="subtitle-1 orange--text text--darken-1">0,00%</small>
    <small v-else class="subtitle-1" :class="isZero ? 'orange--text text--darken-1' : isGTZero ? 'green--text' : 'red--text'">
      {{!isZero ? $options.filters.percent(getPercent(valueA, valueB),2, 100, { decimalSeparator: ',' }) : '0,00%' }}
    </small>
  </v-layout>
</template>

<script>
export default {
  props: ['valueA','valueB', 'isInt'],
  data: () => ({
    iconUp: 'mdi-arrow-up-thick',
    iconDown: 'mdi-arrow-down-thick',
    iconMiddle: 'mdi-arrow-up-down-bold'
  }),
  computed: {
    getPercent() {
      return (valueA, valueB) => Number(valueA) > 0.00 ? ((Number(valueA)-Number(valueB)) / Number(valueA)) : -1
    },
    isZero() {
      return (this.getPercent(this.valueA, this.valueB) || 0) === (this.isInt ? 0 : 0.00)
    },
    isGTZero() {
      return (this.getPercent(this.valueA, this.valueB) || 0) > (this.isInt ? 0 : 0.00)
    }
  }
}
</script>

<style scoped>

</style>
