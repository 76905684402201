<template>
  <div class="home-kardex-brief">
    <custom-filters :show-affiliates="true"
                    :affiliate-default="millennium.kardexAffiliateDefault"
                    :start-date="`2019-01-01`"
                    :end-date="this.getDate(new Date())"
                    affiliates-cols="12"
                    :affiliate-multiples="false"
                    :affiliate-clearable="false"
                    :on-search="onSearch"
                    :status-success="millennium.kardexs.length > 0"
                    :loading="millennium.kardexsStatus === 1"
                    loading-text="Buscando informações aguarde..."
    />
    <v-layout v-if="!millennium.kardexs || (millennium.kardexsStatus !== 1 && millennium.kardexs.length === 0)" justify-start justify-center>
      <i class="grey--text py-4 subtitle-1">Nenhuma informação foi encontrada.</i>
    </v-layout>
    <div v-else-if="millennium.kardexsStatus === 0 && millennium.kardexs.length > 0" class="px-4 mt-0 mb-5">
      <v-data-table fixed-header disable-pagination hide-default-footer :headers="headers" :items="millennium.kardexs" dense disable-sort>
        <template #item.details="{item}" class="teste">
          <div class="details-container">
            <v-layout align-start class="py-2 flex-grow-1">
              <v-layout column class="pl-3 pt-0" style="width: 100%">
                <div class="pt-0 font-weight-bold" style="background-color: #1a237e; color: #fff;  padding: 2px 5px;">
                  <div style="margin-top: 5px">
                    {{item.year}}
                  </div>
                </div>
                <div class="field field-unidades">Unidades (Qtd Vendida)</div>
                <div class="field field-faturamento">Faturamento</div>
                <div v-if="millennium.kardexs.indexOf(item) >= 1" class="field field-crescimento">
                  {{`Crescimento ${millennium.kardexs[millennium.kardexs.indexOf(item)-1].year} vs. ${millennium.kardexs[millennium.kardexs.indexOf(item)].year} %`}}
                </div>
                <div class="field field-margem-bruta">Margem Bruta %</div>
                <div class="field field-ticket">Ticket Médio</div>
                <div class="field field-qtd-devolvidas">Qtd. Und. Devolvidas</div>
                <div class="field field-val-devolvidas">Valor Und. Devolvidas</div>
                <div class="field field-estoque-val">Estoque (Valor)</div>
                <div class="field field-estoque-und">Estoque (Unidades)</div>
              </v-layout>
            </v-layout>
          </div>
        </template>
        <template #item.months.jan="{item}">
          <div class="details-container">
            <v-layout align-center class="py-2">

              <v-layout column class="pl-3">
                <div class="caption text-uppercase">janeiro</div>
                <div class="field field-unidades">{{getField('jan','quantidade_vendida', item)}}</div>
                <div class="field field-faturamento">{{currency(getField('jan','valor_faturamento', item))}}</div>
                <div v-if="millennium.kardexs.indexOf(item) >= 1" class="field field-crescimento">
                  <div>
                    {{formatPercent(getPercent(item, millennium.kardexs, 'jan', 'valor_faturamento'))}}
                  </div>
                </div>
                <div class="field field-margem-bruta">{{currency(getField('jan','margem_bruta', item))}}</div>
                <div class="field field-ticket">{{getField('jan','quantidade_vendida', item) === 0 ? 'R$ 0,00' : currency(getField('jan','valor_faturamento', item) / getField('jan','quantidade_vendida', item))}}</div>
                <div class="field field-qtd-devolvidas">{{getField('jan','quantidade_devolvida', item)}}</div>
                <div class="field field-val-devolvidas">{{currency(getField('jan','valor_devolvido', item))}}</div>
                <div class="field field-estoque-val">{{currency(getField('jan','valor_estoque', item))}}</div>
                <div class="field field-estoque-und">{{currency(getField('jan','estoque', item))}}</div>
              </v-layout>
            </v-layout>
          </div>
        </template>
        <template #item.months.fev="{item}">
          <div class="details-container">
            <v-layout align-center class="py-2">

              <v-layout column class="pl-3">
                <div class="caption text-uppercase">fevereiro</div>
                <div class="field field-unidades">{{getField('fev','quantidade_vendida', item)}}</div>
                <div class="field field-faturamento">{{currency(getField('fev','valor_faturamento', item))}}</div>
                <div v-if="millennium.kardexs.indexOf(item) >= 1" class="field field-crescimento">
                  <div>
                    {{formatPercent(getPercent(item, millennium.kardexs, 'fev', 'valor_faturamento'))}}
                  </div>
                </div>
                <div class="field field-margem-bruta">{{currency(getField('fev','margem_bruta', item))}}</div>
                <div class="field field-ticket">{{getField('fev','quantidade_vendida', item) === 0 ? 'R$ 0,00' : currency(getField('fev','valor_faturamento', item) / getField('fev','quantidade_vendida', item))}}</div>
                <div class="field field-qtd-devolvidas">{{getField('fev','quantidade_devolvida', item)}}</div>
                <div class="field field-val-devolvidas">{{currency(getField('fev','valor_devolvido', item))}}</div>
                <div class="field field-estoque-val">{{currency(getField('fev','valor_estoque', item))}}</div>
                <div class="field field-estoque-und">{{currency(getField('fev','estoque', item))}}</div>
              </v-layout>
            </v-layout>
          </div>
        </template>
        <template #item.months.mar="{item}">
          <div class="details-container">
            <v-layout align-center class="py-2">

              <v-layout column class="pl-3">
                <div class="caption text-uppercase">março</div>
                <div class="field field-unidades">{{getField('mar','quantidade_vendida', item)}}</div>
                <div class="field field-faturamento">{{currency(getField('mar','valor_faturamento', item))}}</div>
                <div v-if="millennium.kardexs.indexOf(item) >= 1" class="field field-crescimento">
                  <div>
                    {{formatPercent(getPercent(item, millennium.kardexs, 'mar', 'valor_faturamento'))}}
                  </div>
                </div>
                <div class="field field-margem-bruta">{{currency(getField('mar','margem_bruta', item))}}</div>
                <div class="field field-ticket">{{getField('mar','quantidade_vendida', item) === 0 ? 'R$ 0,00' :  currency(getField('mar','valor_faturamento', item) / getField('mar','quantidade_vendida', item))}}</div>
                <div class="field field-qtd-devolvidas">{{getField('mar','quantidade_devolvida', item)}}</div>
                <div class="field field-val-devolvidas">{{currency(getField('mar','valor_devolvido', item))}}</div>
                <div class="field field-estoque-val">{{currency(getField('mar','valor_estoque', item))}}</div>
                <div class="field field-estoque-und">{{currency(getField('mar','estoque', item))}}</div>
              </v-layout>
            </v-layout>
          </div>
        </template>
        <template #item.months.abr="{item}">
          <div class="details-container">
            <v-layout align-center class="py-2">

              <v-layout column class="pl-3">
                <div class="caption text-uppercase">abril</div>
                <div class="field field-unidades">{{getField('abr','quantidade_vendida', item)}}</div>
                <div class="field field-faturamento">{{currency(getField('abr','valor_faturamento', item))}}</div>
                <div v-if="millennium.kardexs.indexOf(item) >= 1" class="field field-crescimento">
                  <div>
                    {{formatPercent(getPercent(item, millennium.kardexs, 'abr', 'valor_faturamento'))}}
                  </div>
                </div>
                <div class="field field-margem-bruta">{{currency(getField('abr','margem_bruta', item))}}</div>
                <div class="field field-ticket">{{getField('abr','quantidade_vendida', item) === 0 ? 'R$ 0,00' :  currency(getField('abr','valor_faturamento', item) / getField('abr','quantidade_vendida', item))}}</div>
                <div class="field field-qtd-devolvidas">{{getField('abr','quantidade_devolvida', item)}}</div>
                <div class="field field-val-devolvidas">{{currency(getField('abr','valor_devolvido', item))}}</div>
                <div class="field field-estoque-val">{{currency(getField('abr','valor_estoque', item))}}</div>
                <div class="field field-estoque-und">{{currency(getField('abr','estoque', item))}}</div>
              </v-layout>
            </v-layout>
          </div>
        </template>
        <template #item.months.mai="{item}">
          <div class="details-container">
            <v-layout align-center class="py-2">

              <v-layout column class="pl-3">
                <div class="caption text-uppercase">maio</div>
                <div class="field field-unidades">{{getField('mai','quantidade_vendida', item)}}</div>
                <div class="field field-faturamento">{{currency(getField('mai','valor_faturamento', item))}}</div>
                <div v-if="millennium.kardexs.indexOf(item) >= 1" class="field field-crescimento">
                  <div>
                    {{formatPercent(getPercent(item, millennium.kardexs, 'mai', 'valor_faturamento'))}}
                  </div>
                </div>
                <div class="field field-margem-bruta">{{currency(getField('mai','margem_bruta', item))}}</div>
                <div class="field field-ticket">{{getField('mai','quantidade_vendida', item) === 0 ? 'R$ 0,00' :  currency(getField('mai','valor_faturamento', item) / getField('mai','quantidade_vendida', item))}}</div>
                <div class="field field-qtd-devolvidas">{{getField('mai','quantidade_devolvida', item)}}</div>
                <div class="field field-val-devolvidas">{{currency(getField('mai','valor_devolvido', item))}}</div>
                <div class="field field-estoque-val">{{currency(getField('mai','valor_estoque', item))}}</div>
                <div class="field field-estoque-und">{{currency(getField('mai','estoque', item))}}</div>
              </v-layout>
            </v-layout>
          </div>
        </template>
        <template #item.months.jun="{item}">
          <div class="details-container">
            <v-layout align-center class="py-2">

              <v-layout column class="pl-3">
                <div class="caption text-uppercase">junho</div>
                <div class="field field-unidades">{{getField('jun','quantidade_vendida', item)}}</div>
                <div class="field field-faturamento">{{currency(getField('jun','valor_faturamento', item))}}</div>
                <div v-if="millennium.kardexs.indexOf(item) >= 1" class="field field-crescimento">
                  <div>
                    {{formatPercent(getPercent(item, millennium.kardexs, 'jun', 'valor_faturamento'))}}
                  </div>
                </div>
                <div class="field field-margem-bruta">{{currency(getField('jun','margem_bruta', item))}}</div>
                <div class="field field-ticket">{{getField('jun','quantidade_vendida', item) === 0 ? 'R$ 0,00' :  currency(getField('jun','valor_faturamento', item) / getField('jun','quantidade_vendida', item))}}</div>
                <div class="field field-qtd-devolvidas">{{getField('jun','quantidade_devolvida', item)}}</div>
                <div class="field field-val-devolvidas">{{currency(getField('jun','valor_devolvido', item))}}</div>
                <div class="field field-estoque-val">{{currency(getField('jun','valor_estoque', item))}}</div>
                <div class="field field-estoque-und">{{currency(getField('jun','estoque', item))}}</div>
              </v-layout>
            </v-layout>
          </div>
        </template>
        <template #item.months.jul="{item}">
          <div class="details-container">
            <v-layout align-center class="py-2">

              <v-layout column class="pl-3">
                <div class="caption text-uppercase">julho</div>
                <div class="field field-unidades">{{getField('jul','quantidade_vendida', item)}}</div>
                <div class="field field-faturamento">{{currency(getField('jul','valor_faturamento', item))}}</div>
                <div v-if="millennium.kardexs.indexOf(item) >= 1" class="field field-crescimento">
                  <div>
                    {{formatPercent(getPercent(item, millennium.kardexs, 'jul', 'valor_faturamento'))}}
                  </div>
                </div>
                <div class="field field-margem-bruta">{{currency(getField('jul','margem_bruta', item))}}</div>
                <div class="field field-ticket">{{getField('jul','quantidade_vendida', item) === 0 ? 'R$ 0,00' :  currency(getField('jul','valor_faturamento', item) / getField('jul','quantidade_vendida', item))}}</div>
                <div class="field field-qtd-devolvidas">{{getField('jul','quantidade_devolvida', item)}}</div>
                <div class="field field-val-devolvidas">{{currency(getField('jul','valor_devolvido', item))}}</div>
                <div class="field field-estoque-val">{{currency(getField('jul','valor_estoque', item))}}</div>
                <div class="field field-estoque-und">{{currency(getField('jul','estoque', item))}}</div>
              </v-layout>
            </v-layout>
          </div>
        </template>
        <template #item.months.ago="{item}">
          <div class="details-container">
            <v-layout align-center class="py-2">

              <v-layout column class="pl-3">
                <div class="caption text-uppercase">agosto</div>
                <div class="field field-unidades">{{getField('ago','quantidade_vendida', item)}}</div>
                <div class="field field-faturamento">{{currency(getField('ago','valor_faturamento', item))}}</div>
                <div v-if="millennium.kardexs.indexOf(item) >= 1" class="field field-crescimento">
                  <div>
                    {{formatPercent(getPercent(item, millennium.kardexs, 'ago', 'valor_faturamento'))}}
                  </div>
                </div>
                <div class="field field-margem-bruta">{{currency(getField('ago','margem_bruta', item))}}</div>
                <div class="field field-ticket">{{getField('ago','quantidade_vendida', item) === 0 ? 'R$ 0,00' :  currency(getField('ago','valor_faturamento', item) / getField('ago','quantidade_vendida', item))}}</div>
                <div class="field field-qtd-devolvidas">{{getField('ago','quantidade_devolvida', item)}}</div>
                <div class="field field-val-devolvidas">{{currency(getField('ago','valor_devolvido', item))}}</div>
                <div class="field field-estoque-val">{{currency(getField('ago','valor_estoque', item))}}</div>
                <div class="field field-estoque-und">{{currency(getField('ago','estoque', item))}}</div>
              </v-layout>
            </v-layout>
          </div>
        </template>
        <template #item.months.set="{item}">
          <div class="details-container">
            <v-layout align-center class="py-2">

              <v-layout column class="pl-3">
                <div class="caption text-uppercase">setembro</div>
                <div class="field field-unidades">{{getField('set','quantidade_vendida', item)}}</div>
                <div class="field field-faturamento">{{currency(getField('set','valor_faturamento', item))}}</div>
                <div v-if="millennium.kardexs.indexOf(item) >= 1" class="field field-crescimento">
                  <div>
                    {{formatPercent(getPercent(item, millennium.kardexs, 'set', 'valor_faturamento'))}}
                  </div>
                </div>
                <div class="field field-margem-bruta">{{currency(getField('set','margem_bruta', item))}}</div>
                <div class="field field-ticket">{{getField('set','quantidade_vendida', item) === 0 ? 'R$ 0,00' :  currency(getField('set','valor_faturamento', item) / getField('set','quantidade_vendida', item))}}</div>
                <div class="field field-qtd-devolvidas">{{getField('set','quantidade_devolvida', item)}}</div>
                <div class="field field-val-devolvidas">{{currency(getField('set','valor_devolvido', item))}}</div>
                <div class="field field-estoque-val">{{currency(getField('set','valor_estoque', item))}}</div>
                <div class="field field-estoque-und">{{currency(getField('set','estoque', item))}}</div>
              </v-layout>
            </v-layout>
          </div>
        </template>
        <template #item.months.out="{item}">
          <div class="details-container">
            <v-layout align-center class="py-2">

              <v-layout column class="pl-3">
                <div class="caption text-uppercase">outubro</div>
                <div class="field field-unidades">{{getField('out','quantidade_vendida', item)}}</div>
                <div class="field field-faturamento">{{currency(getField('out','valor_faturamento', item))}}</div>
                <div v-if="millennium.kardexs.indexOf(item) >= 1" class="field field-crescimento">
                  <div>
                    {{formatPercent(getPercent(item, millennium.kardexs, 'out', 'valor_faturamento'))}}
                  </div>
                </div>
                <div class="field field-margem-bruta">{{currency(getField('out','margem_bruta', item))}}</div>
                <div class="field field-ticket">{{getField('out','quantidade_vendida', item) === 0 ? 'R$ 0,00' :  currency(getField('out','valor_faturamento', item) / getField('out','quantidade_vendida', item))}}</div>
                <div class="field field-qtd-devolvidas">{{getField('out','quantidade_devolvida', item)}}</div>
                <div class="field field-val-devolvidas">{{currency(getField('out','valor_devolvido', item))}}</div>
                <div class="field field-estoque-val">{{currency(getField('out','valor_estoque', item))}}</div>
                <div class="field field-estoque-und">{{currency(getField('out','estoque', item))}}</div>
              </v-layout>
            </v-layout>
          </div>
        </template>
        <template #item.months.nov="{item}">
          <div class="details-container">
            <v-layout align-center class="py-2">

              <v-layout column class="pl-3">
                <div class="caption text-uppercase">novembro</div>
                <div class="field field-unidades">{{getField('nov','quantidade_vendida', item)}}</div>
                <div class="field field-faturamento">{{currency(getField('nov','valor_faturamento', item))}}</div>
                <div v-if="millennium.kardexs.indexOf(item) >= 1" class="field field-crescimento">
                  <div>
                    {{formatPercent(getPercent(item, millennium.kardexs, 'nov', 'valor_faturamento'))}}
                  </div>
                </div>
                <div class="field field-margem-bruta">{{currency(getField('nov','margem_bruta', item))}}</div>
                <div class="field field-ticket">{{getField('nov','quantidade_vendida', item) === 0 ? 'R$ 0,00' :  currency(getField('nov','valor_faturamento', item) / getField('nov','quantidade_vendida', item))}}</div>
                <div class="field field-qtd-devolvidas">{{getField('nov','quantidade_devolvida', item)}}</div>
                <div class="field field-val-devolvidas">{{currency(getField('nov','valor_devolvido', item))}}</div>
                <div class="field field-estoque-val">{{currency(getField('nov','valor_estoque', item))}}</div>
                <div class="field field-estoque-und">{{currency(getField('nov','estoque', item))}}</div>
              </v-layout>
            </v-layout>
          </div>
        </template>
        <template #item.months.dez="{item}">
          <div class="details-container">
            <v-layout align-center class="py-2">

              <v-layout column class="pl-3">
                <div class="caption text-uppercase">dezembro</div>
                <div class="field field-unidades">{{getField('dez','quantidade_vendida', item)}}</div>
                <div class="field field-faturamento">{{currency(getField('dez','valor_faturamento', item))}}</div>
                <div v-if="millennium.kardexs.indexOf(item) >= 1" class="field field-crescimento">
                  <div>
                    {{formatPercent(getPercent(item, millennium.kardexs, 'dez', 'valor_faturamento'))}}
                  </div>
                </div>
                <div class="field field-margem-bruta">{{currency(getField('dez','margem_bruta', item))}}</div>
                <div class="field field-ticket">{{getField('dez','quantidade_vendida', item) === 0 ? 'R$ 0,00' :  currency(getField('dez','valor_faturamento', item) / getField('dez','quantidade_vendida', item))}}</div>
                <div class="field field-qtd-devolvidas">{{getField('dez','quantidade_devolvida', item)}}</div>
                <div class="field field-val-devolvidas">{{currency(getField('dez','valor_devolvido', item))}}</div>
                <div class="field field-estoque-val">{{currency(getField('dez','valor_estoque', item))}}</div>
                <div class="field field-estoque-und">{{currency(getField('dez','estoque', item))}}</div>
              </v-layout>
            </v-layout>
          </div>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import CustomFilters from "@/components/pages/home/custom-filters";
export default {
  components: {CustomFilters},
  data: () => ({
    headers: [
      {text: '', value: 'details'},
      {text: 'JAN', value: 'months.jan', width: 480},
      {text: 'FEV', value: 'months.fev', width: 250},
      {text: 'MAR', value: 'months.mar', width: 250},
      {text: 'ABR', value: 'months.abr', width: 250},
      {text: 'MAI', value: 'months.mai', width: 250},
      {text: 'JUN', value: 'months.jun', width: 250},
      {text: 'JUL', value: 'months.jul', width: 250},
      {text: 'AGO', value: 'months.ago', width: 250},
      {text: 'SET', value: 'months.set', width: 250},
      {text: 'OUT', value: 'months.out', width: 250},
      {text: 'NOV', value: 'months.nov', width: 250},
      {text: 'DEZ', value: 'months.dez', width: 250},
    ],
  }),
  computed: {
    millennium() {
      return this.$store.state.millennium;
    },
    currency() {
      return value => this.$options.filters.currency(value, 'R$ ', 2, { spaceBetweenAmountAndSymbol: true, thousandsSeparator: '.', decimalSeparator: ',' })
    }
  },
  methods: {
    getDate(date) {
      return date.toLocaleDateString().split('/').reverse().join('-');
    },
    formatPercent(value) {
      return value !== 0.00 ? this.$options.filters.percent(value,2,100, { decimalSeparator: ',' }) : '0,00%'
    },
    getPercent(item, data, month, field) {
      const current = data.indexOf(item)
      const prev = current-1
      return Array.isArray(data) && (data[prev].months[month] && data[current].months[month]) && ((data[prev].months[month][field]-data[current].months[month][field]) / data[prev].months[month][field])
    },
    getField(month, field, data) {
      return data.months[month] ? data.months[month][field] : 0
    },
    onSearch(form, success, error) {

      const {
        startDate,
        endDate,
        affiliateSelected
      } = form

      this.$store.dispatch('millennium/getKardexBrief', {
        startDate,
        endDate,
        affiliate: affiliateSelected
      })
          .then(success)
          .catch(error)

    }
  }
}
</script>

<style lang="scss">
.home-kardex-brief {

  .details-container {
    //height: 350px;
    width: 100%;

    .caption {
      background: #1a237e!important;
      color: #fff;
      padding: 4px 0;
      font-weight: 600;
    }

    .field {
      padding: 10px 0;
      width: 100%;
    }
  }

  .v-data-table {
    position: relative;
  }

  .v-data-table__wrapper {
    //height: calc(100vh - 260px)!important;
    overflow-y: hidden!important;
    padding-bottom: 20px;
  }

  .v-data-table--fixed-header th {
    background-color: #1a237e!important;
  }

  table {

    tr td {
      padding-right: 0!important;
      padding-left: 0!important;

      .layout {
        padding-left: 0!important;
        padding-top: 0!important;

      .field {
          padding-left: 5px!important;
        }
      }
    }



    tr:nth-child(1) td:nth-child(1) {
      background: #fff!important;
      border: 0 !important;
      height: 368px!important;
    }

    tr:nth-child(n+1) td:nth-child(1) {
      background: #fff!important;
      border: 0 !important;
      height: 406px;
    }



    tr td:nth-child(1), thead tr th:nth-child(1) {
      position: absolute!important;
      left: 0;
      z-index: 3;
      width: 236px;
    }

    tr td:nth-child(2), thead tr th:nth-child(2) {
      padding-left: 236px!important;

    }

    thead tr {
      background-color: #1a237e;

      th {
        color: #eee !important;
        text-transform: uppercase;
        visibility: hidden!important;
        height: 1px!important;

        span {
          padding-left: 16px;
        }

        .v-input, label, .v-icon.v-icon::after, .v-icon.v-icon::before {
          color: #ffffff !important;
          caret-color: #ffffff !important;
        }

        .v-label {
          color: #ffffff !important;
          caret-color: #ffffff !important;
        }

        .sortable .v-icon::before, .sortable .v-icon::after {
          color: #111111 !important;
        }
      }
    }
  }
}
</style>
