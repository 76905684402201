<template>
  <v-layout column class="pt-5 flex-grow-1">
    <div>
      <line-chart
        :chart-data="fillData()"
        :options="chartOptions"
        :plugins="[CustomLegendPlugin]"
        :styles="getChartReactiveStyles"
      />
      <div id="line-chart-legend" class="px-5"></div>
    </div>
  </v-layout>
</template>

<script>
import LineChart from "@/components/charts/line-chart";

export default {
  props: ["importedData"],
  components: { LineChart },
  data: () => ({
    CustomLegendPlugin: {
      beforeInit(chart) {
        let html = `<div class="row py-4">`;
        chart.data.datasets.forEach((item, i) => {
          html += `
                <div id="legend-item-${i}" class="col col-3 d-flex" style="font-size: 12px; cursor: pointer">
                 <div class="d-flex column justify-center align-center">
                    <span class="legend-marker-item" data-color="${item.borderColor}" style="margin: 1px 4px; width: 4px; padding: 4px; border-radius: 4px; background: ${item.borderColor}"></span>
                 </div>
                  <span class="legend-item-label">${item.label}</span>
                </div>
          `;
        });

        html += `</div>`;

        return (document.getElementById("line-chart-legend").innerHTML = html);
      },
      afterInit(chart) {
        const updateDataset = (currentEl, index) => {
          const labelEl = currentEl.querySelector(".legend-item-label");
          const markerEl = currentEl.querySelector(".legend-marker-item");

          const result = chart.getDatasetMeta(index).hidden !== true;

          if (result === true) {
            chart.getDatasetMeta(index).hidden = true;
            labelEl.style.textDecoration = "line-through";
            labelEl.style.color = "#bbb";
            markerEl.style.background = "#bbb";
          } else {
            chart.getDatasetMeta(index).hidden = false;
            labelEl.style.textDecoration = "none";
            labelEl.style.color = "#000000de";
            markerEl.style.background = markerEl.dataset.color;
          }

          chart.update();
        };

        chart.data.datasets.forEach((item, i) => {
          const el = document.getElementById("legend-item-" + i);

          el.onclick = function () {
            updateDataset(el, i);
          };
        });
      },
    },
    chartOptions: {
      elements: {
        line: {
          tension: 0,
        },
      },
      legend: {
        display: false,
        align: "start",
        position: "bottom",
      },
      maintainAspectRatio: false,
      scales: {
        yAxes: [
          {
            ticks: {
              callback: (value) => {
                return value.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                });
              },
            },
          },
        ],
        y: {
          stacked: true,
          grid: {
            display: true,
            color: "rgba(255,99,132,0.2)",
          },
        },
        x: {
          grid: {
            display: false,
          },
        },
      },
    },
  }),
  computed: {
    sales() {
      return this.$store.state.millennium;
    },
    years() {
      return Object.keys(this.sales.salesLastYears);
    },
    getChartReactiveStyles() {
      let styles = {
        position: "relative",
        margin: "auto",
        height: this.$store.state.drawer ? "50vh" : "40vh",
        width: this.$store.state.drawer ? "50vw" : "40vw",
      };

      if (this.$store.state.showFullscreenChart) {
        styles.minWidth = "90vw";
        styles.minHeight = "80vh";
      } else {
        styles.minWidth = "40vw";
        styles.minHeight = "50vh";
      }

      if (this.$vuetify.breakpoint.mobile) {
        styles.minWidth = "83vw";
        styles.minHeight = "29vh";

        if (this.$store.state.showFullscreenChart) {
          styles.minWidth = "95vw";
          styles.minHeight = "70vh";
        }
      }

      if (
        !this.$store.state.showFullscreenChart &&
        this.$vuetify.breakpoint.height < 500
      ) {
        styles.minWidth = "43vw";
        styles.minHeight = "29vh";
      }

      return styles;
    },
  },
  methods: {
    fillData() {
      const huePool = [];
      const hues = [
        0, 17, 34, 51, 68, 85, 102, 119, 136, 153, 170, 187, 204, 221, 238, 255,
        272, 289, 306, 323, 340, 360,
      ];

      const generateNewColor = (pool) => {
        const hue = () => hues[Math.floor(Math.random() * 21)];

        let newHue = hue();

        while (pool.includes(newHue)) {
          pool.push(newHue);
        }

        return newHue;
      };

      const channels = this.importedData.map((e) => e.name);

      const fillData = {
        labels: [
          "Jan",
          "Fev",
          "Mar",
          "Abr",
          "Mai",
          "Jun",
          "Jul",
          "Ago",
          "Set",
          "Out",
          "Nov",
          "Dez",
        ],
        datasets: [],
      };

      channels.map((name) => {
        const hue = generateNewColor(huePool);

        const foregroundColor = `hsl(${hue}, 78%, 42%)`;
        const backgroundColor = `hsl(${hue}, 23%, 71%)`;

        const item = this.importedData.find((e) => e.name === name);

        const dataset = {
          label: name,
          pointBorderColor: foregroundColor,
          pointHoverBorderColor: foregroundColor,
          pointHoverBackgroundColor: foregroundColor,
          pointBackgroundColor: foregroundColor,
          backgroundColor: backgroundColor,
          borderColor: foregroundColor,
          borderWidth: 2,
          hoverBackgroundColor: backgroundColor,
          hoverBorderColor: foregroundColor,
          data: [
            item.jan.current,
            item.fev.current,
            item.mar.current,
            item.abr.current,
            item.mai.current,
            item.jun.current,
            item.jul.current,
            item.ago.current,
            item.set.current,
            item.out.current,
            item.nov.current,
            item.dez.current,
          ],
        };

        fillData.datasets.push(dataset);
      });

      return fillData;
    },
  },
};
</script>

<style scoped>
</style>
