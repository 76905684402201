<template>
  <div class="home-data-table mt-10 pb-5">
    <div v-if="sales.salesChannelMonthTotalStatus === 1 || sales.salesChannelYearMonthTotalStatus === 1">
      <v-layout justify-center align-center column>
        <v-progress-circular indeterminate width="3" color="#1a237e" />
        <div class="caption pt-3">atualizando</div>
        <div class="caption pt-3">{{sales.salesChannelMonthTotalStatusInfo}}</div>
      </v-layout>
    </div>
    <div v-else-if="exportedDataToChart.length > 0">
      <chart :imported-data="exportedDataToChart" />
      <v-data-table class="mt-10" disable-pagination hide-default-footer :headers="headers" :items="exportedDataToChart" disable-sort>
        <template #no-data>
          <div class="d-flex justify-start">
            <i>nenhuma informação disponível</i>
          </div>
        </template>
        <template #item.pos="{item}">
          <span>{{item.pos}}</span>
        </template>
        <template #item.jan="{item}">
          <month-item :item="item.jan" />
        </template>
        <template #item.fev="{item}">
          <month-item :item="item.fev" />
        </template>
        <template #item.mar="{item}">
          <month-item :item="item.mar" />
        </template>
        <template #item.abr="{item}">
          <month-item :item="item.abr" />
        </template>
        <template #item.mai="{item}">
          <month-item :item="item.mai" />
        </template>
        <template #item.jun="{item}">
          <month-item :item="item.jun" />
        </template>
        <template #item.jul="{item}">
          <month-item :item="item.jul" />
        </template>
        <template #item.ago="{item}">
          <month-item :item="item.ago" />
        </template>
        <template #item.set="{item}">
          <month-item :item="item.set" />
        </template>
        <template #item.out="{item}">
          <month-item :item="item.out" />
        </template>
        <template #item.nov="{item}">
          <month-item :item="item.nov" />
        </template>
        <template #item.dez="{item}">
          <month-item :item="item.dez" />
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import mixins from '@/mixins'
import MonthItem from "@/components/pages/home/data-table-sales-channel/month-item";
import Chart from "@/components/pages/home/data-table-sales-channel/chart";


export default {
  components: {Chart, MonthItem},
  mixins: [mixins],
  props: ['tabSelected'],
  data: () => ({
    pos: 0,
    headers: [
      {text: '#', value: 'pos'},
      {text: 'Canal Venda', value: 'name', width: 120},
      {text: 'Jan', value: 'jan', width: 140},
      {text: 'Fev', value: 'fev', width: 140},
      {text: 'Mar', value: 'mar', width: 140},
      {text: 'Abr', value: 'abr', width: 140},
      {text: 'Mai', value: 'mai', width: 140},
      {text: 'Jun', value: 'jun', width: 140},
      {text: 'Jul', value: 'jul', width: 140},
      {text: 'Ago', value: 'ago', width: 140},
      {text: 'Set', value: 'set', width: 140},
      {text: 'Out', value: 'out', width: 140},
      {text: 'Nov', value: 'nov', width: 140},
      {text: 'Dez', value: 'dez', width: 140},
    ],
    exportedDataToChart: []
  }),
  computed: {
    sales() {
      return this.$store.state.millennium;
    },
    years() {
      return Object.keys(this.sales.salesLastYears)
    },
  },
  watch: {
    '$store.state.millennium.salesChannelYearMonthTotalStatus'(next, prev) {
      if(prev === 1 && next === 0) {
        this.setExportedData(this.getData())
      }
    },
    'tabSelected'(next) {
      if(next) {
        this.setExportedData(this.getData())
      }
    }
  },
  mounted() {
    this.setExportedData(this.getData())
  },
  methods: {
    setExportedData(data) {
      setTimeout(() => {
        this.exportedDataToChart = data
      }, 500)
    },
    getData() {

      // cria o array de dados
      const data = []

      // obtem os anos anteriores que foram definidos
      const years = Object.assign([], this.years)

      // inicia o contador de itens
      let i = 1;

      // linha abaixo necessario para disparar a renderezação apos
      // recarregamento dos dados
      console.debug(this.sales.salesChannelMonthTotal)

      // obtem um array de nomes únicos
      const channelNames = Object.keys(this.sales.salesChannelMonthTotal.amount)
          .reduce((names, month) => {
            const channelNames = this.sales.salesChannelMonthTotal.amount[month].map(e => e.name);
            names = new Set([...names, ...channelNames]);
            return names;
          }, [])

      // percorre cada nome de canal de venda
      channelNames.forEach((name) => {

        // cria um objeto com o nome do canal de venda
        let channel = {name}

        // verificar se todos os meses deram valor zero, buscando pelo nome do canal de venda atual
        const hasAllMonthAsAmountZero = Object.keys(this.sales.salesChannelMonthTotal.amount)
            .map((month) => this.sales.salesChannelMonthTotal.amount[month]
                .find(e => e.value === 0 && e.name === name)).filter(e => e).length

        // percorre os 12 meses
        this.sales.months.map((month) => {

          // obtem um item de mes
          const itemMonth = this.sales.salesChannelMonthTotal.amount[month]

          // verifica se o item não existe retorna erro no console
          if (!itemMonth) return console.error('Item month undefined: ', itemMonth)

          // obtem um item de canal de venda pelo nome
          const itemChannel = itemMonth.find(e => e.name === name)

          // cria o objeto de mes, com valor do ano atual
          const objMonth = {current: itemChannel ? itemChannel.value : 0}

          // percorre os anos anteriores
          years.forEach(year => {

            // obtem um item de ano
            const itemYear = this.sales.salesChannelYearMonthTotal[year]

            // verifica se o item de ano existe e se o mes também é existente
            if(itemYear && itemYear.amount[month]) {

              // obtem um item de canal de venda pelo nome
              const itemChannel = itemYear.amount[month].find(e => e.name === name)

              // cria o objeto de mes, com valor do ano atual
              objMonth[year] = itemChannel ? itemChannel.value : 0

              // se o item de ano não existe...
            } else {

              // atribui o valor no ano com zero
              objMonth[year] = 0

            }
          })

          // no objeto de canal no mẽs atual, atribui o objeto de mês
          channel[month] = objMonth;

        })

        // incrementa o contador de itens
        channel.pos = i++;

        // so adiciona o objeto de canal de venda
        // se pelo menos um mes tem valor acima de zero
        if(!hasAllMonthAsAmountZero)
          data.push(Object.assign({}, channel))

      });

      // console.log(data)

      // retorna os canais de vendas
      // com o valor de cada mes do ano atual
      // e com o valor de cada mes dos anos anteriores
      return data
    }
  }
}
</script>

<style lang="scss">
.home-data-table {
  table {
    thead tr {
      background-color: #1a237e;

      th {
        color: #eee !important;
        text-transform: uppercase;

        .v-input, label, .v-icon.v-icon::after, .v-icon.v-icon::before {
          color: #ffffff !important;
          caret-color: #ffffff !important;
        }

        .v-label {
          color: #ffffff !important;
          caret-color: #ffffff !important;
        }

        .sortable .v-icon::before, .sortable .v-icon::after {
          color: #111111 !important;
        }
      }
    }
  }
}
</style>
