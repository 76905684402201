<template>
  <div class="custom-filters pa-3">
    <v-snackbar v-model="$data.showSnackbar" top color="error">
      {{$data.messageSnackbar}}
    </v-snackbar>
    <v-row>
      <!-- FILTER BUTTONS -->
      <v-col>
        <v-layout v-if="!loading" justify-space-between wrap align-center>
          <div title="filtrar">
            <v-btn icon @click="onToggleFilter">
              <v-icon v-if="!$data.showFilters" color="light-blue lighten-1">mdi-filter</v-icon>
              <v-icon v-else color="grey">mdi-close</v-icon>
            </v-btn>
            <span v-if="!$data.showFilters" class="caption grey--text">filtros</span>
            <span v-else class="caption grey--text">fechar</span>
          </div>
        </v-layout>
      </v-col>
    </v-row>
    <!-- SELECT DATES -->
    <v-layout class="grey lighten-4 pa-1 px-3 pt-3" column v-show="$data.showFilters">
      <v-row>
        <!-- START DATE -->
        <v-col cols="12" md="6">
          <v-text-field type="date" label="Data Inicial" v-model="$data.form.startDate" />
        </v-col>
        <!-- END DATE -->
        <v-col cols="12" md="6">
          <v-text-field type="date" label="Data Final" v-model="$data.form.endDate"/>
        </v-col>
      </v-row>
      <v-row>
        <!-- SELECT BRANDS -->
        <v-col v-if="showBrands" :cols="brandsCols">
          <v-select :items="millennium.brands" item-value="cod_marca" dense clearable v-model="$data.form.brandSelected" label="Marca">
            <template #selection="{item}">
              <div style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap; width: 100%">
                {{item.cod_marca}}{{item.descricao ? ' - ' + item.descricao.replace(/\W|\d/g,'') : ''}}
              </div>
            </template>
            <template #item="{item}">
              {{item.cod_marca}}{{item.descricao ? ' - ' + item.descricao.replace(/\W|\d/g,'') : ''}}
            </template>
          </v-select>
        </v-col>
        <!-- SELECT CHANNELS -->
        <v-col v-if="showChannels"  :cols="channelsCols">
          <v-select :items="millennium.channels" item-value="codigo" dense clearable v-model="$data.form.channelSelected" label="Canal de Venda">
            <template #selection="{item}">
              <div style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap; width: 100%">
                {{item.codigo}}{{item.descricao ? ' - ' + item.descricao.replace(/\W|\d/g,'') : ''}}
              </div>
            </template>
            <template #item="{item}">
              {{item.codigo}}{{item.descricao ? ' - ' + item.descricao.replace(/\W|\d/g,'') : ''}}
            </template>
          </v-select>
        </v-col>
        <!-- SELECT AFFILIATES -->
        <v-col v-if="showAffiliates"  :cols="affiliatesCols">
          <v-select :items="millennium.affiliates" item-value="cod_filial" :item-text="item => `${item.cod_filial} - ${item.nome_fantasia}`"
                    dense
                    :multiple="affiliateMultiples"
                    :small-chips="affiliateMultiples"
                    :clearable="affiliateClearable"
                    :deletable-chips="affiliateMultiples"
                    v-model="$data.form.affiliateSelected"
                    label="Filiais"
          />
        </v-col>
      </v-row>
      <!-- ACTIONS & ALERTS -->
      <v-row class="px-3 pb-4">
        <!-- Buscar -->
        <v-col cols="12" class="d-flex flex-column justify-center justify-md-start pa-0 py-3">
          <div v-if="$data.getStatusBuscar !== 1" class="d-flex flex-grow-1 flex-md-grow-0">
            <v-btn :block="$vuetify.breakpoint.smOnly" color="primary" @click="_onSearch($data.form)">
              Buscar
            </v-btn>
          </div>
          <div v-if="$data.getStatusBuscar === 1" class="d-flex flex-column py-4" style="width: 100%">
            <span class="caption">buscando...</span>
            <v-progress-linear indeterminate />
          </div>
          <div v-if="$data.getStatusBuscar === -1" class="d-flex pt-4" style="width: 100%">
            <v-alert color="red lighten-4 red--text text--lighten-1" class="pa-2">
              Algo de errado ocorreu, tente novamente.
            </v-alert>
          </div>
        </v-col>
      </v-row>
    </v-layout>
    <!-- Alert Success -->
    <div v-if="$data.showAlertSuccess && statusSuccess" class="d-flex pt-2 pb-0" style="width: 100%">
      <v-alert color="success lighten-5 green--text text--darken-1" class="pa-2 ml-3 mb-0">
        <v-icon small color="success" class="mr-1">mdi-check-circle</v-icon>
        <span>resultados encontrados com sucesso!</span>
      </v-alert>
    </div>
    <!-- SHOW CHIPS -->
    <v-row v-if="!$data.showFilters" class="pl-3 ma-3 grey lighten-4" style="border: solid 1px #ddd !important; border-radius: 3px">
      <v-col>
        <v-layout wrap>
          <!-- START DATE -->
          <div class="mr-3">
            <div class="caption grey--text">data inicial</div>
            <div class="caption">{{$data.form.startDate.split('-').reverse().join('/')}}</div>
          </div>
          <v-divider vertical class="mr-3"></v-divider>
          <!-- END DATE -->
          <div>
            <div class="caption grey--text">data final</div>
            <div class="caption">{{$data.form.endDate.split('-').reverse().join('/')}}</div>
          </div>
        </v-layout>
      </v-col>
      <!-- CHIP BRANDS -->
      <v-col cols="4" v-if="showBrands && $data.form.brandSelected">
        <div class="caption grey--text" v-if="$data.form.brandSelected">
          marca
        </div>
        <span v-if="$data.form.brandSelected" class="grey lighten-4 mr-3" style="text-overflow: ellipsis!important; white-space: pre-line; font-size: 10px">
          {{getBrand(millennium.brands.find(e => e.cod_marca === $data.form.brandSelected))}}
        </span>
      </v-col>
      <!-- CHIP CHANNELS -->
      <v-col :cols="!$data.form.brandSelected ? 8 : 4" v-if="showChannels && $data.form.channelSelected">
        <div class="caption grey--text" v-if="$data.form.channelSelected">
          canal de venda
        </div>
        <span v-if="$data.form.channelSelected" class="grey lighten-4 mr-3" style="text-overflow: ellipsis!important; white-space: pre-line; font-size: 10px">
          {{getChannel(millennium.channels.find(e => e.codigo === $data.form.channelSelected))}}
        </span>
      </v-col>
      <!-- CHIP AFFILIATES -->
      <v-col cols="12" v-if="showAffiliates">
        <div class="caption grey--text" v-if="
        (affiliateMultiples && Array.isArray($data.form.affiliateSelected) && $data.form.affiliateSelected.length > 0) ||
        (!Array.isArray($data.form.affiliateSelected) && $data.form.affiliateSelected)
        ">
          {{ affiliateMultiples ? 'filiais' : 'filial' }}
        </div>
        <div v-if="affiliateMultiples">
          <v-row no-gutters>
            <v-col cols="4" v-for="(item, i) in $data.form.affiliateSelected" :key="i" class="py-1 pr-3">
              <span class="grey lighten-4 mr-3" style="text-overflow: ellipsis!important; white-space: pre-line; font-size: 10px">
                {{getAffiliate(millennium.affiliates.find(e => e.cod_filial === item))}}
              </span>
            </v-col>
          </v-row>
        </div>
        <span v-else-if="!Array.isArray($data.form.affiliateSelected) && $data.form.affiliateSelected" class="grey lighten-4 mr-3" style="text-overflow: ellipsis!important; white-space: pre-line; font-size: 10px">
          {{getAffiliate(millennium.affiliates.find(e => e.cod_filial === $data.form.affiliateSelected))}}
        </span>
      </v-col>
    </v-row>
    <!-- CUSTOM LOADING -->
    <div v-if="loading && $data.getStatusBuscar !== 1">
      <v-layout justify-start align-start column class="px-3">
        <div class="caption pt-3">{{loadingText || 'buscando...'}}</div>
        <v-progress-linear indeterminate width="3" color="#1a237e" />
      </v-layout>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    'onSearch',
    'showBrands',
    'brandsCols',
    'showAffiliates',
    'affiliatesCols',
    'affiliateDefault',
    'showChannels',
    'channelsCols',
    'affiliateMultiples',
    'affiliateClearable',
    'statusSuccess',
    'startDate',
    'endDate',
    'loading',
    'loadingText'
  ],
  data: function () { return ({
    showFilters: false,
    showAlertSuccess: false,
    getStatusBuscar: 0,
    form: {
      startDate: this.getDate(new Date()),
      endDate: this.getDate(new Date()),
      brandSelected: '',
      channelSelected: '',
      affiliateSelected: ''
    },
    showSnackbar: false,
    messageSnackbar: '',
  })},
  mounted() {
    if (!this.affiliateMultiples) this.$data.form.affiliateSelected = this.affiliateDefault

    if(this.startDate && this.endDate) {
      this.$data.form.startDate = this.startDate
      this.$data.form.endDate = this.endDate
    }
  },
  computed: {
    millennium() {
      return this.$store.state.millennium;
    },
    // hasFilters() {
    //   const hasArrayAffiliate = Array.isArray(this.$data.form.affiliateSelected) && this.$data.form.affiliateSelected.length > 0
    //   return hasArrayAffiliate || this.$data.form.affiliateSelected || this.$data.form.channelSelected || this.$data.form.brandSelected
    // }
    // getStatusBuscar() {
    //   return this.$store.state.millennium.status
    // }
  },
  methods: {
    getDate(date) {
      return date.toLocaleDateString().split('/').reverse().join('-');
    },
    getAffiliate(data) {
      return !data ? '' : `${data.cod_filial} - ${data.razao_social}`
    },
    getBrand(data) {
      return `${data.cod_marca}${data.descricao ? ' - ' + data.descricao.replace(/\\W|\\d/g,'') : ''}`
    },
    getChannel(data) {
      return `${data.codigo}${data.descricao ? ' - ' + data.descricao.replace(/\\W|\\d/g,'') : ''}`
    },
    onToggleFilter() {
      this.showFilters = !this.showFilters;
    },
    _onSearch(form) {

      this.getStatusBuscar = 1

      const success = () => {
        this.getStatusBuscar = 0;
        this.showFilters = false;
        this.showAlertSuccess = true
        setTimeout(() => this.showAlertSuccess = false, 2500)

      }

      const error = (err) => {
        this.getStatusBuscar = -1;
        this.showAlertSuccess = false

        if(err && [401, 403].includes(err.status)) {
          this.$data.showSnackbar = true;
          this.$data.messageSnackbar = 'Sessão expirou, redirecionando para o login...';
          setTimeout(() => {
            this.$router.push({name: 'Login'})
            this.$store.dispatch('auth/revokeAthentication')
          }, 2000)
        }

      }

      this.onSearch(form, success, error)

    }
  }
}
</script>

<style scoped>

</style>
