<template>
  <div>
    <div v-if="item && item.current" style="font-size: 11px" class="font-weight-medium pt-4">{{ currentYear }}</div>
    <div v-else style="font-size: 11px" class="font-weight-medium pt-4">&nbsp;</div>
    <div v-if="item && item.current" class="font-weight-medium">{{ currency(item.current) }}</div>
    <div v-else class="font-weight-medium">&nbsp;</div>
    <v-divider class="my-4"></v-divider>
    <div v-for="(year, i) in years" :key="i" class="mb-4">
      <div style="font-size: 9px" class="font-weight-medium">{{year}}</div>
      <div style="font-size: 11px">{{ currency(item[year]) }}</div>
    </div>
  </div>
</template>

<script>
import mixins from "@/mixins";

export default {
  props: ['item'],
  mixins: [mixins],
  data: () => ({
    currentYear: new Date().getFullYear()
  }),
  computed: {
    sales() {
      return this.$store.state.millennium;
    },
    years() {
      return Object.keys(this.sales.salesLastYears)
    }
  }
}
</script>
