var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home-data-table mt-10 pb-5"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.getData(_vm.data),"hide-default-footer":"","disable-sort":""},scopedSlots:_vm._u([{key:"item.jan",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.currency(item.jan)))])]}},{key:"item.fev",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.currency(item.fev)))])]}},{key:"item.mar",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.currency(item.mar)))])]}},{key:"item.abr",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.currency(item.abr)))])]}},{key:"item.mai",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.currency(item.mai)))])]}},{key:"item.jun",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.currency(item.jun)))])]}},{key:"item.jul",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.currency(item.jul)))])]}},{key:"item.ago",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.currency(item.ago)))])]}},{key:"item.set",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.currency(item.set)))])]}},{key:"item.out",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.currency(item.out)))])]}},{key:"item.nov",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.currency(item.nov)))])]}},{key:"item.dez",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.currency(item.dez)))])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }