<script>
import { Line, mixins }  from 'vue-chartjs'
const { reactiveProp } = mixins

export default {
  extends: Line,
  mixins: [reactiveProp],
  props: ['chartData', 'options','plugins'],
  mounted () {
    if(this.plugins) this.addPlugin(this.plugins)
    this.renderChart(this.chartData, this.options)
  },
  watch: {
    chartData () {
      this.$data._chart.update()
    }
  }
}
</script>

