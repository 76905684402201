var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home-data-table mt-10 pb-5"},[(_vm.sales.salesChannelMonthTotalStatus === 1 || _vm.sales.salesChannelYearMonthTotalStatus === 1)?_c('div',[_c('v-layout',{attrs:{"justify-center":"","align-center":"","column":""}},[_c('v-progress-circular',{attrs:{"indeterminate":"","width":"3","color":"#1a237e"}}),_c('div',{staticClass:"caption pt-3"},[_vm._v("atualizando")]),_c('div',{staticClass:"caption pt-3"},[_vm._v(_vm._s(_vm.sales.salesChannelMonthTotalStatusInfo))])],1)],1):(_vm.exportedDataToChart.length > 0)?_c('div',[_c('chart',{attrs:{"imported-data":_vm.exportedDataToChart}}),_c('v-data-table',{staticClass:"mt-10",attrs:{"disable-pagination":"","hide-default-footer":"","headers":_vm.headers,"items":_vm.exportedDataToChart,"disable-sort":""},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('div',{staticClass:"d-flex justify-start"},[_c('i',[_vm._v("nenhuma informação disponível")])])]},proxy:true},{key:"item.pos",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.pos))])]}},{key:"item.jan",fn:function(ref){
var item = ref.item;
return [_c('month-item',{attrs:{"item":item.jan}})]}},{key:"item.fev",fn:function(ref){
var item = ref.item;
return [_c('month-item',{attrs:{"item":item.fev}})]}},{key:"item.mar",fn:function(ref){
var item = ref.item;
return [_c('month-item',{attrs:{"item":item.mar}})]}},{key:"item.abr",fn:function(ref){
var item = ref.item;
return [_c('month-item',{attrs:{"item":item.abr}})]}},{key:"item.mai",fn:function(ref){
var item = ref.item;
return [_c('month-item',{attrs:{"item":item.mai}})]}},{key:"item.jun",fn:function(ref){
var item = ref.item;
return [_c('month-item',{attrs:{"item":item.jun}})]}},{key:"item.jul",fn:function(ref){
var item = ref.item;
return [_c('month-item',{attrs:{"item":item.jul}})]}},{key:"item.ago",fn:function(ref){
var item = ref.item;
return [_c('month-item',{attrs:{"item":item.ago}})]}},{key:"item.set",fn:function(ref){
var item = ref.item;
return [_c('month-item',{attrs:{"item":item.set}})]}},{key:"item.out",fn:function(ref){
var item = ref.item;
return [_c('month-item',{attrs:{"item":item.out}})]}},{key:"item.nov",fn:function(ref){
var item = ref.item;
return [_c('month-item',{attrs:{"item":item.nov}})]}},{key:"item.dez",fn:function(ref){
var item = ref.item;
return [_c('month-item',{attrs:{"item":item.dez}})]}}])})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }