<template>
  <div class="sales-channel-indicatiors fill-height mt-8 px-2 pb-2">
    <v-row>
      <v-col cols="12">
        <v-data-table :headers="headers" :items="sorted()" hide-default-footer disable-sort>
          <template #no-data>
            <i>nenhuma informação disponível</i>
          </template>
          <template #item.pos="{item}">
            <span>{{sorted().indexOf(item) + 1}}</span>
          </template>
          <template #item.name="{item}">
            <span class="indigo--text text--darken-3">{{item.name}}</span>
          </template>
          <template #item.value="{item}">
            <span class="font-weight-medium">{{currency(item.value)}}</span>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
<!--    <v-row class="container-sales-channel overflow-y-auto">-->
<!--      <v-col v-for="(item, i) in sorted()" :key="i" cols="11" sm="4" style="height: 100px">-->
<!--        <v-card>-->
<!--          <v-card-subtitle class="black&#45;&#45;text pb-0 indigo&#45;&#45;text text&#45;&#45;darken-3">-->
<!--            {{ item.name }}-->
<!--          </v-card-subtitle>-->
<!--          <v-card-subtitle class="font-weight-medium black&#45;&#45;text text&#45;&#45;lighten-1">-->
<!--            <span style="font-size: 12px">R$ </span>{{decimal(item.value)}}-->
<!--          </v-card-subtitle>-->
<!--        </v-card>-->
<!--      </v-col>-->
<!--    </v-row>-->
  </div>
</template>

<script>
import mixins from '@/mixins'
export default {
  mixins: [mixins],
  props: ['channels'],
  data: () => ({
    headers: [
      {text: '#', value: 'pos'},
      {text: 'Canal', value: 'name'},
      {text: 'Valor', value: 'value'},
    ]
  }),
  computed: {
    sorted() {
      return () => this.channels.slice().sort((a, b) => b.value - a.value)
    }
  },
  mounted() {
    const page = document.querySelector('.container-page');
    const container = document.querySelector('.container-sales-channel');

    const fn = function () {
      if(this.scrollTop === 0) {
        if (container) container.style.minHeight = 'calc(100vh - 290px)'
      } else if (this.offsetHeight === (this.scrollHeight - this.scrollTop)) {
        if (container) container.style.minHeight = 'calc(100vh - 40px)'
      }
    }

    page.removeEventListener('scroll', fn)
    page.addEventListener('scroll', fn)
  }
}
</script>

<style lang="scss">
.sales-channel-indicatiors {
  .row > div > .v-card {
    border-top: 0.2rem solid rgb(81, 94, 187);
  }
}
</style>
