<template>
  <page class="daily-brief" title="Dashboard">
    <template #toolbar-options>
      <v-btn :ripple="false" class="btn-update-dashboard" v-if="!showBtnSuccess" title="atualizar dashboard" :icon="isMobile" small text
             :color="!isMobile || updateStatus === 1 ? 'primary' : 'grey darken-1'" @click="onUpdate">
        <v-icon :small="!isMobile" class="mr-1" :class="updateStatus === 1 ? 'rotating' : ''" style="margin-bottom: 2px">mdi-sync</v-icon>
        <span v-if="!isMobile">
          <span v-if="updateStatus === 1">atualizando...</span>
          <span v-if="updateStatus !== 1">atualizar</span>
        </span>
      </v-btn>
      <v-btn v-if="showBtnSuccess" :icon="isMobile" small text
             color="success">
        <v-icon :small="!isMobile" class="mr-1" style="margin-bottom: 2px">mdi-check-circle</v-icon>
        <span v-if="!isMobile">
          <span>atualizado</span>
        </span>
      </v-btn>
    </template>
    <v-snackbar v-model="showSnackbar" top color="error">
      <span class="font-weight-bold">{{ messageSnackbar }}</span>
    </v-snackbar>
    <!-- Indicators -->
    <indicators />
    <!-- Chart & Tables & Comparative -->
    <v-row class="flex-grow-0" no-gutters>
      <v-col cols="12" sm="7" class="pr-3 pt-4 pb-1">
         <!-- Chart & Table -->
        <v-tabs v-model="tabChart" class="card-shadow">
          <v-tab>VENDAS</v-tab>
          <v-tab>CANAL DE VENDAS</v-tab>
          <v-tab>KARDEX</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tabChart" class="card-shadow">
          <v-tab-item>
            <chart-table-panel />
          </v-tab-item>
          <v-tab-item>
            <data-table-sales-channel :tab-selected="tabChart === 1" />
          </v-tab-item>
          <v-tab-item>
            <kardex-brief />
          </v-tab-item>
        </v-tabs-items>
      </v-col>
      <!-- Comparative & Sales Channel-->
      <v-col cols="12" sm="5" class="pr-3 pt-4 pb-1">
        <v-tabs v-model="tab" class="card-shadow">
          <v-tab>CANAL DE VENDAS</v-tab>
          <v-tab>COMPARATIVO</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab" class="card-shadow">
          <v-tab-item>
            <sales-channel-indicators :channels="sales.salesChannel" />
          </v-tab-item>
          <v-tab-item>
            <comparative />
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </page>
</template>

<script>
import Page from "@/components/containers/page";
import mixins from "@/mixins"
import Indicators from "@/components/pages/home/indicators";
import Comparative from "@/components/pages/home/comparative";
import ChartTablePanel from "@/components/pages/home/chart-table-panel";
import SalesChannelIndicators from "@/components/widgets/sales-channel-indicators";
import DataTableSalesChannel from "@/components/pages/home/data-table-sales-channel";
import KardexBrief from "@/components/pages/home/kardex-brief";

export default {
  mixins: [mixins],
  components: {
    KardexBrief,
    DataTableSalesChannel, SalesChannelIndicators, Comparative, Indicators, Page, ChartTablePanel},
  data: () => ({
    showSnackbar: false,
    showBtnSuccess: false,
    messageSnackbar: '',
    updateStatus: 0,
    tab: null,
    tabChart: null
  }),
  mounted() {
    this.onUpdate(true);

    this.$store.dispatch('millennium/getAffiliates');
    this.$store.dispatch('millennium/getBrands');
    this.$store.dispatch('millennium/getChannels');
  },
  computed: {
    sales() {
      return this.$store.state.millennium;
    },
    isMobile() {
      return Number(this.$vuetify.breakpoint.width) < 600
    }
  },
  methods: {
    getDate(date) {
      return date.toLocaleDateString().split('/').reverse().join('-');
    },
    onUpdate(isMounted) {

      if(isMounted || this.sales.salesStatus !== 1) {

        if (this.$store.getters["auth/isAuthenticated"]) {

          this.updateStatus = 1

          this.$store.commit('millennium/setSalesChannelMonthTotalStatusInfo', 'preparando para obter dados...')


          // Obter Margem Bruta do Ano Atual
          // this.$store.dispatch('millennium/getCMV', {
          //   startDate: this.sales.startDateCMV,
          //   endDate: this.sales.endDateCMV
          // })


          // Obter Kardex com data e filial Atual
          this.$store.dispatch('millennium/getKardexBrief', {
            startDate: `2019-01-01`,
            endDate: this.getDate(new Date()),
            affiliate: this.sales.kardexAffiliateDefault
          })

          // Obter Vendas Hoje
          this.$store.dispatch('millennium/getSales', {
            type: 'today',
            startDate: this.getDate(new Date()),
            endDate: this.getDate(new Date())
          })
              .then(() => {

                // atualiza a data
                this.$store.commit('millennium/setDateTimeUpdate')
                this.$store.commit('millennium/setSalesChannelMonthTotalStatusInfo', 'obtendo dados de cada mês do ano atual...')

                // Obter Vendas Por Todos os Mês do Ano Atual
                this.$store.dispatch('millennium/getSales', {
                  type: 'months',
                  startDate: `${new Date().getFullYear()}-01-01`,
                  endDate: this.getDate(new Date())
                })
                    .then(() => {

                      // Obter Comparativos
                      this.$store.dispatch('millennium/getComparativeSales', {
                        currentStartDate: this.sales.currentStartDate,
                        currentEndDate: this.sales.currentEndDate,
                        previousStartDate: this.sales.previousStartDate,
                        previousEndDate: this.sales.previousEndDate
                      })
                          .then(() => {

                            this.$store.commit("millennium/setComparatives")
                            if(document.querySelector('.container-comparatives')) document.querySelector('.container-comparatives').scrollTo(0, 0);
                            if(document.querySelector('.container-page')) document.querySelector('.container-page').scrollTo(0, 0);

                            // Obter Vendas Nos Ultimos Anos
                            getSalesLastYears(1, () => {
                              this.showBtnSuccess = true;
                              setTimeout(() => this.showBtnSuccess = false, 2000)
                              this.updateStatus = 0
                            })

                          })
                          .catch((err) => this.catchErrors(err))
                    })
                    .catch((err) => this.catchErrors(err))

              })
              .catch((err) => this.catchErrors(err))
        } else {
          this.sessionExpire()
        }
      }

      const self = this;
      function getSalesLastYears(years, success) {

        self.$store.commit('millennium/setSalesChannelMonthTotalStatusInfo', 'obtendo dados de cada mês do ano anterior...')

        Array(years).fill(null)
            .map((e,i) => {
              self.$store.dispatch('millennium/getSales', {
                type: 'years',
                startDate: `${new Date().getFullYear()-(i+1)}-01-01`,
                endDate:  `${new Date().getFullYear()-(i+1)}-12-31`
              })
                  .then(() => {
                    self.$store.commit('millennium/setSalesChannelMonthTotalStatus', 0)
                    self.$store.commit('millennium/setSalesChannelYearMonthTotalStatus', 0)
                    success && success()
                  })
                  .catch((err) => self.catchErrors(err))
            })
      }
    }
  }
}
</script>

<style lang="scss">
.btn-update-dashboard:focus::before {
  opacity: 0!important;
}

@media(max-width: 599px)  {
  .btn-update-dashboard:hover::before {
    opacity: 0!important;
  }
}
</style>
