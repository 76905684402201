<template>
  <div class="home-data-table mt-10 pb-5">
    <v-data-table :headers="headers" :items="getData(data)" hide-default-footer disable-sort>
      <template #item.jan="{item}">
        <span>{{ currency(item.jan) }}</span>
      </template>
      <template #item.fev="{item}">
        <span>{{ currency(item.fev) }}</span>
      </template>
      <template #item.mar="{item}">
        <span>{{ currency(item.mar) }}</span>
      </template>
      <template #item.abr="{item}">
        <span>{{ currency(item.abr) }}</span>
      </template>
      <template #item.mai="{item}">
        <span>{{ currency(item.mai) }}</span>
      </template>
      <template #item.jun="{item}">
        <span>{{ currency(item.jun) }}</span>
      </template>
      <template #item.jul="{item}">
        <span>{{ currency(item.jul) }}</span>
      </template>
      <template #item.ago="{item}">
        <span>{{ currency(item.ago) }}</span>
      </template>
      <template #item.set="{item}">
        <span>{{ currency(item.set) }}</span>
      </template>
      <template #item.out="{item}">
        <span>{{ currency(item.out) }}</span>
      </template>
      <template #item.nov="{item}">
        <span>{{ currency(item.nov) }}</span>
      </template>
      <template #item.dez="{item}">
        <span>{{ currency(item.dez) }}</span>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  props: ['indicator'],
  data: () => ({
    headers: [
      {text: 'Ano', value: 'year', width: 80},
      {text: 'Jan', value: 'jan', width: 140},
      {text: 'Fev', value: 'fev', width: 140},
      {text: 'Mar', value: 'mar', width: 140},
      {text: 'Abr', value: 'abr', width: 140},
      {text: 'Mai', value: 'mai', width: 140},
      {text: 'Jun', value: 'jun', width: 140},
      {text: 'Jul', value: 'jul', width: 140},
      {text: 'Ago', value: 'ago', width: 140},
      {text: 'Set', value: 'set', width: 140},
      {text: 'Out', value: 'out', width: 140},
      {text: 'Nov', value: 'nov', width: 140},
      {text: 'Dez', value: 'dez', width: 140},
    ],
    data: [
      {year: new Date().getFullYear(), jan: 0, fev: 0, mar: 0, abr: 0, mai: 0, jun: 0, jul: 0, ago: 0, set: 0, out: 0, nov: 0, dez: 0},
      {year: new Date().getFullYear() - 1, jan: 0, fev: 0, mar: 0, abr: 0, mai: 0, jun: 0, jul: 0, ago: 0, set: 0, out: 0, nov: 0, dez: 0},
    ]
  }),
  computed: {
    sales() {
      return this.$store.state.millennium;
    },
    currency() {
      return value => this.indicator !== 1 && this.indicator !== 3
          ? this.$options.filters.currency(value, 'R$ ', 2, {
            spaceBetweenAmountAndSymbol: true,
            thousandsSeparator: '.',
            decimalSeparator: ','
          })
          : this.number(value)
    },
  },
  methods: {
    number(value) {
      return !value ? 0 : this.$options.filters.currency(value, '', 0, { spaceBetweenAmountAndSymbol: false, thousandsSeparator: '.', decimalSeparator: ',' })
    },
    getData(data) {
      if (!data) return []

      console.debug(this.sales.salesStatus)

      Object.keys(this.sales.salesLastYears)
          .map((year,i) => {

            data[i+1].year = year

            switch (this.indicator) {
              case 0:
                this.sales.months.map((month) => {
                  data[0][month] = this.sales.salesMonthTotal.amount[month]
                  if(i+1 > 0) {
                    data[i+1][month] = this.sales.salesLastYears[year].amount[month]
                  }
                });
                break;
              case 1:
                this.sales.months.map((month) => {
                  data[0][month] = this.sales.salesMonthTotal.sales[month]
                  if(i+1 > 0) {
                    data[i+1][month] = this.sales.salesLastYears[year].sales[month]
                  }
                });
                break;
              case 2:
                this.sales.months.map((month) => {
                  data[0][month] = this.sales.salesMonthTotal.average[month]
                  if(i+1 > 0) {
                    data[i+1][month] = this.sales.salesLastYears[year].average[month]
                  }
                });
                break;
              case 3:
                this.sales.months.map((month) => {
                  data[0][month] = this.sales.salesMonthTotal.quant[month]
                  if(i+1 > 0) {
                    data[i+1][month] = this.sales.salesLastYears[year].quant[month]
                  }
                });
            }
          })

      return data;
    }
  }
}
</script>

<style lang="scss">
.home-data-table {
  table {
    thead tr {
      background-color: #1a237e;

      th {
        color: #eee !important;
        text-transform: uppercase;

        .v-input, label, .v-icon.v-icon::after, .v-icon.v-icon::before {
          color: #ffffff !important;
          caret-color: #ffffff !important;
        }

        .v-label {
          color: #ffffff !important;
          caret-color: #ffffff !important;
        }

        .sortable .v-icon::before, .sortable .v-icon::after {
          color: #111111 !important;
        }
      }
    }
  }
}
</style>
